<template>
  <List />
</template>

<script>
import List from '../../../components/admin/internal_updates/List';

export default {
  name: 'InternalOverview',
  components: {
    List
  }
};
</script>
